:root {
  --clr-primary: #DB2428;
  --clr-grey: #979898;
  --clr-black: #231f20;
  --clr-white: #fff;
}

.btn {
  min-width: 160px;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 10px 15px;
  line-height: 1rem;
}
.btn .icon {
  display: inline-block;
  transition: 0.3s;
}
.btn:hover {
  color: var(--clr-primary);
}
.btn:hover .icon {
  transform: translateX(5px);
  transition: 0.3s;
}

.btn-sm {
  min-width: 90px;
}

.btn-outline-red {
  color: var(--clr-primary);
  border-color: var(--clr-primary);
}
.btn-outline-red:hover {
  color: var(--clr-primary);
}

.btn-primary {
  background: var(--clr-primary);
  border-color: var(--clr-primary);
  box-shadow: 0px 6px 20px 1px rgba(253, 7, 65, 0.34);
}
.btn-primary:hover, .btn-primary:focus {
  background: var(--clr-primary);
  border-color: var(--clr-primary);
  color: #fff;
  box-shadow: 0px 6px 30px 1px rgba(253, 7, 65, 0.54);
}

.btn-secondary {
  background: var(--clr-white);
  border-color: var(--clr-white);
  color: var(--clr-primary);
  box-shadow: 0px 6px 20px 1px rgba(0, 0, 0, 0.15);
}
.btn-secondary:hover, .btn-secondary:focus {
  background: var(--clr-white);
  border-color: var(--clr-white);
  color: var(--clr-primary);
  box-shadow: 0px 6px 25px 1px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 1200px) {
  .btn {
    padding: 15px 20px;
    min-width: 200px;
  }
  .btn-sm {
    min-width: 90px;
    padding: 10px;
  }
}
@media screen and (max-width: 500px) {
  .btn {
    font-size: 0.7rem;
  }
}
body {
  font-family: "Lato", sans-serif;
}

p {
  line-height: 2rem;
}

.title {
  margin-bottom: 20px;
  color: var(--clr-black);
  font-weight: 600;
  font-size: 24px;
}
.title span {
  color: var(--clr-primary);
  display: block;
}

.para-text {
  font-size: 16px;
  line-height: 2rem;
  color: #4a4a4a;
}

.py-10 {
  padding: 100px 0;
}

/*.menu-bar {
	height: 30px;
	width: 30px;
	position: relative;
	cursor: pointer;

	span {
		height: 1px;
		width: 30px;
		background: var(--clr-primary);
		display: block;
		position: absolute;
	}

	span:nth-child(1) {

	}

	span:nth-child(2) {
		top: 10px;
	}

	span:nth-child(3) {
		top: 20px;
	}


}
*/
.contact-page p em {
  color: var(--clr-primary);
}
.contact-page .address p {
  line-height: 1rem;
}
.contact-page .content-wrapper {
  justify-content: center;
}
.contact-page .content-wrapper .card {
  margin: 20px 1%;
  border-radius: 25px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border: 0;
}
.contact-page .content-wrapper .card figure {
  text-align: center;
}
.contact-page .content-wrapper .card figure img {
  height: 350px;
  object-fit: contain;
  width: 100%;
}

body {
  background: #f2f2f2;
}

.navbar {
  background: transparent;
  padding: 25px 0;
  /*
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;*/
  /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
  z-index: 2;
}
.navbar .nav-item .nav-link {
  padding: 10px 26px !important;
  color: #333;
}
.navbar .nav-item .nav-link:hover {
  color: var(--clr-primary);
}
.navbar .nav-item .dropdown-toggle {
  position: relative;
}
.navbar .nav-item .dropdown-toggle .icon {
  position: absolute;
  right: 5px;
  top: 10px;
}
.navbar .nav-item .dropdown-toggle .icon svg {
  height: 15px;
  width: 15px;
}
.navbar .nav-item .dropdown-toggle::after {
  display: none;
}
.navbar .nav-item .dropdown-menu {
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.navbar .nav-item .dropdown-menu li a {
  padding: 10px;
  color: #333;
}
.navbar .nav-item .dropdown-menu li a:hover {
  color: var(--clr-primary);
  background: #f5f5f5;
}

.hero-section {
  background: #F2F2F2;
  position: relative;
  /*height: 95vh;*/
}
.hero-section .red-wave {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
}
.hero-section .content {
  display: flex;
}
.hero-section .content .left-content {
  flex-basis: 50%;
}
.hero-section .content .left-content .content-wrapper {
  margin-top: 20px;
}
.hero-section .content .left-content .title {
  text-align: center;
}
.hero-section .content .left-content p {
  text-align: center;
}
.hero-section .content .right-content {
  flex-basis: 50%;
}
.hero-section .content .right-content .images {
  position: relative;
}
.hero-section .content .right-content .images img {
  object-fit: contain;
  height: 300px;
  width: 100%;
}
.hero-section .content .right-content .images .img1 {
  /*	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);*/
}

.donate {
  position: relative;
  background: url("../images/wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.donate .image img {
  width: 100%;
  height: 325px;
}

.indicator {
  color: #fff;
  display: inline-block;
  padding: 1px 10px;
  border-radius: 3px;
  background: var(--clr-primary);
  margin-bottom: 10px;
  margin-top: 10px;
  letter-spacing: 0.05rem;
  font-size: 0.9rem;
}

.events {
  padding-top: 350px;
}
.events .list .content-wrapper a {
  text-decoration: none;
  flex-basis: 100%;
  margin: 0 1% 25px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}
.events .list .content-wrapper a:hover .content h5 {
  color: var(--clr-primary);
}
.events .list .content-wrapper a figure img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.events .list .content-wrapper a .content {
  padding: 0 15px 10px;
}
.events .list .content-wrapper a .content h5 {
  color: var(--clr-black);
  font-size: 0.9rem;
  font-weight: 600;
}
.events .list .content-wrapper a .content .date {
  font-size: 0.9rem;
  color: #666;
  color: #888;
  display: block;
}
.events .content {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 20px;
}
.events .content .right-content {
  padding: 0 10px;
}
.events .content .right-content h5 {
  font-weight: 600;
  font-size: 1.5rem;
}
.events .images img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 25px;
}
.events .link {
  display: block;
  margin-bottom: 10px;
  color: var(--clr-primary);
  text-decoration: none;
}
.events .date {
  font-size: 0.9rem;
  color: #666;
  color: #888;
  display: block;
}

.join-community .content .left-content .image img {
  width: 100%;
  object-fit: contain;
}
.join-community .content .right-content {
  flex-basis: 50%;
}

.mentorship {
  background: #f4f4f4;
  /*position: relative;
  .red-wave-sm {
  	position: absolute;
  	bottom: 0;
  	right: 0;
  }
  */
}
.mentorship .content .content-wrapper {
  /*text-align: center;*/
}
.mentorship .content .content-wrapper .card {
  padding: 10px;
  box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 20px;
  margin-bottom: 25px;
}
.mentorship .content .content-wrapper .card h5 {
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}
.mentorship .content .content-wrapper .card p {
  /*margin-bottom: 40px;*/
  /*min-height: 130px;*/
}
.mentorship .content .content-wrapper .card img {
  height: 200px;
  object-fit: contain;
  width: 100%;
}

.slider {
  background: var(--clr-black);
  position: relative;
  min-height: 600px;
}
.slider .content {
  /*position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  /*margin-top: 180px;*/
}
.slider .content .content-wrapper {
  display: flex;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.slider .content .content-wrapper > div {
  /*flex-basis: 50%;*/
}
.slider .content .content-wrapper .left-content {
  /*padding: 6%;*/
}
.slider .content .content-wrapper .left-content h5 {
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 10%;
  /*color: var(--clr-primary);*/
}
.slider .content .content-wrapper .right-content .image {
  position: relative;
  margin-top: 15px;
}
.slider .content .content-wrapper .right-content .image img {
  width: 100%;
  /*height: 500px;
  object-fit:contain;*/
}
.slider .content .content-wrapper .right-content .image .name {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
}
.slider .content .content-wrapper .right-content .image .name small {
  display: block;
  font-size: 0.8rem;
}

.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
  height: 60px;
  width: 60px;
  background: var(--clr-primary);
  border-radius: 50%;
  text-align: center;
  line-height: 3.5rem;
  display: none;
  cursor: pointer;
}
.scroll-top svg {
  height: 40px;
  width: 40px;
}

.credit {
  position: relative;
}
.credit:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.credit a {
  color: var(--clr-black);
}

.wia-program .content-wrapper {
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
}

.heading {
  font-size: 1.1rem;
  color: var(--clr-primary);
  font-weight: 600;
}

.event-page {
  padding: 40px 0;
  max-width: 700px;
  margin: 0 auto;
}
.event-page h1 {
  font-weight: 800;
}
.event-page p {
  text-align: justify;
  font-size: 1.08rem;
}
.event-page .date {
  margin-bottom: 30px;
  display: block;
  color: #888;
}
.event-page figure {
  margin-bottom: 30px;
}
.event-page figure img {
  width: 100%;
  object-fit: contain;
}
.event-page .event-information p {
  margin-bottom: 3px;
}
.event-page .event-info {
  margin-bottom: 30px;
  /*	background: #ddd;*/
}
.event-page .event-info h2 {
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--clr-primary);
  margin-bottom: 25px;
}
.event-page .event-info table {
  border: 1px solid #c9c9c9;
}
.event-page .event-info table td, .event-page .event-info table th {
  padding: 8px 10px;
  border-color: #c9c9c9;
}
.event-page .event-info table th {
  border-right: 1px solid #c9c9c9;
}

footer {
  background: var(--clr-black);
}
footer .copyright-text {
  color: #ddd;
  text-align: center;
  margin-top: 30px;
}
footer .content .link-grp .links h5 {
  color: #eee;
  font-size: 22px;
}
footer .content .link-grp .links ul {
  padding-left: 15px;
}
footer .content .link-grp .links ul li::marker {
  color: var(--clr-primary);
}
footer .content .link-grp .links ul li a {
  color: #eee;
  text-decoration: none;
}
footer .content .social-media h5 {
  color: #fff;
}
footer .content .social-media .links a {
  display: inline-block;
}

@media screen and (min-width: 1025px) {
  .hero-section .content .left-content .content-wrapper {
    position: relative;
    top: 40%;
    transform: translateY(-50%);
  }
  .hero-section {
    height: 95vh;
  }
  .hero-section .red-wave {
    display: block;
  }
  .hero-section .red-wave svg {
    height: 90vh;
  }
  .hero-section .content {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hero-section .content .left-content .content-wrapper {
    /*margin-top: 40px;*/
  }
  .hero-section .content .left-content .content-wrapper p,
  .hero-section .content .left-content .content-wrapper .title {
    text-align: left;
  }
  .hero-section .content .right-content {
    flex-basis: 50%;
  }
  .hero-section .content .right-content .images {
    position: relative;
    margin-top: -50px;
  }
  .hero-section .content .right-content .images img {
    object-fit: contain;
    height: 500px;
    width: 100%;
  }
  .join-community .content {
    display: flex;
  }
  .join-community .content .left-content {
    flex-basis: 45%;
    margin-right: 4%;
  }
  .join-community .content .left-content .image img {
    width: 100%;
    object-fit: contain;
  }
  .join-community .content .right-content {
    flex-basis: 50%;
    /*.title {
    	text-align: right;
    }
    p {
    	text-align: right;
    }
    */
  }
  .join-community .content .right-content button {
    float: right;
  }
  .title {
    margin-bottom: 40px;
    font-size: 52px !important;
  }
  .hero-section .content .left-content {
    padding-right: 40px;
  }
  .donate .content {
    display: flex;
  }
  .donate .content .left-content {
    flex-basis: 45%;
  }
  .donate .content .right-content {
    flex-basis: 55%;
    position: relative;
  }
  .donate .content .right-content button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .donate .content .right-content img {
    margin-right: -20px;
  }
  .mentorship .content .content-wrapper {
    display: flex;
    /*text-align: center;*/
  }
  .mentorship .content .content-wrapper .card {
    flex-basis: 48%;
    padding: 40px;
    padding: 30px;
    margin: 0 1%;
  }
  .mentorship .content .content-wrapper .card img {
    height: 350px;
  }
  .wia-program .content-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .wia-program .content-wrapper .left-content {
    flex-basis: 50%;
  }
  .wia-program .content-wrapper .left-content img {
    width: 100%;
    margin-top: 90px;
    object-fit: contain;
    height: 500px;
  }
  .wia-program .content-wrapper .right-content {
    flex-basis: 45%;
  }
  .events {
    /*background: #eee;*/
  }
  .events .list .content-wrapper a {
    flex-basis: 20%;
    margin: 0 1% 25px;
  }
  .events .list .content-wrapper a figure img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  .events .content {
    display: flex;
    padding: 20px;
  }
  .events .content .left-content {
    flex-basis: 40%;
  }
  .events .content .right-content {
    flex-basis: 60%;
    padding: 0 20px;
  }
  .events .content .right-content p {
    margin-bottom: 3px;
  }
  .events .content .right-content h5 {
    margin-top: 0;
  }
  .slider {
    position: relative;
    min-height: 600px;
  }
  .slider .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 180px;
  }
  .slider .content .content-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px 4%;
  }
  .slider .content .content-wrapper .left-content {
    flex-basis: 37%;
  }
  .slider .content .content-wrapper .right-content {
    flex-basis: 65%;
  }
  .slider .content .content-wrapper .left-content h5 {
    font-weight: 600;
    font-size: 2rem;
    margin-top: 10%;
  }
  .slider .content .content-wrapper .left-content p {
    line-height: 1rem;
  }
  .slider .content .content-wrapper .right-content .image {
    position: relative;
  }
  .slider .content .content-wrapper .right-content .image .name {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #fff;
  }
  footer .content {
    display: flex;
  }
  footer .content .link-grp {
    display: flex;
    flex-basis: 60%;
  }
  footer .content .link-grp .links {
    flex-basis: 33.33%;
    color: #fff;
  }
  .contact-page .content-wrapper {
    display: flex;
    justify-content: center;
  }
  .contact-page .content-wrapper .card {
    flex-basis: 100%;
    margin: 20px 1%;
    padding: 20px;
  }
}
.logo {
  height: 80px;
  object-fit: contain;
}

.coming-soon .card {
  border: 0;
  box-shadow: 0px 10px 33px 0px rgba(0, 0, 0, 0.2);
  max-width: 1000px;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 500px;
  padding: 40px;
  overflow: hidden;
}
.coming-soon .card figure {
  margin-top: 10%;
}
.coming-soon .card p {
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 20px;
  padding-left: 10px;
}
.coming-soon .card .wave-lg {
  position: absolute;
  bottom: 0;
  right: 0;
}
.coming-soon .card .wave-sm {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 1199px) {
  .events {
    background: #eee;
  }
  .events .list .content-wrapper a {
    flex-basis: 45%;
    margin: 0 1% 25px;
  }
  .slider .content .content-wrapper {
    flex-direction: column-reverse;
  }
  /*.slider .content .content-wrapper .right-content .image img {
  	height: 400px;
  }*/
  .slider .content .content-wrapper .left-content h5 {
    margin-top: 10px;
  }
  .slider .content .content-wrapper .left-content {
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  nav {
    border-bottom: 1px solid #eee;
  }
  .navbar-nav {
    display: none;
  }
  .navbar-brand img {
    height: 40px;
  }
  .py-10 {
    padding: 60px 0;
  }
  .hero-section .content {
    flex-direction: column-reverse;
  }
  .join-community .title {
    margin-top: 25px;
  }
  .coming-soon .card {
    height: 380px;
    width: 85%;
  }
  .coming-soon .card figure {
    margin-top: 10px;
  }
  .coming-soon .card p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 10px;
  }
  .coming-soon .card .wave-lg svg {
    height: 160px;
  }
  .coming-soon .card .wave-sm svg {
    height: 100px;
  }
  .wia-program .content-wrapper .left-content img {
    width: 100%;
    margin-top: 0;
    object-fit: contain;
    height: 300px;
  }
}
@media screen and (max-width: 425px) {
  .events .list .content-wrapper a {
    flex-basis: 100%;
  }
}